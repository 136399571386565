<script setup>

const route = useRoute();
const router = useRouter();
const isOpen = ref(false);
const sort_array = ref([
  {
    label: 'client.default',
    sort: {}
  },
  {
    label: 'client.latest_property',
    sort: { sort_by: 'id', sort_direction: 'desc' }
  },
  {
    label: 'client.highest_price',
    sort: { sort_by: 'sale_price', sort_direction: 'desc' }
  },
  {
    label: 'client.lowest_price',
    sort: { sort_by: 'sale_price', sort_direction: 'asc' }
  },
]);

    const handleSort = async ({ sort_by, sort_direction }) => {
    isOpen.value = false;
    if (!sort_by) {
      const newQuery = { ...route.query };
      delete newQuery[`sort_by`];
      delete newQuery[`sort_direction`];
      return await router.push({
        query: { ...newQuery, page: 1 },
      })
    }

    await router.push({
      query: { ...route.query, sort_by: sort_by, sort_direction: sort_direction, page: 1 },
    })
  }
</script>
<template>
  <button @click="isOpen = true" class="flex items-center gap-1 text-sm">
    <i class="fa-solid fa-sort"></i>
    <span>Sort</span>
  </button>
  <w-slideover v-model="isOpen" :isBottom="true">

    <div class="sort p-4">
      <h1 class="mb-3 text-2xl">Sort</h1>
      <div class="mt-6 space-y-3">
        <div class="flex items-center border-b pb-2" v-for="(item, idx) in sort_array" :key="idx">
          <button @click="handleSort(item.sort)" class="w-full text-left">
            {{ $t(item.label) }}
          </button>
        </div>
      </div>
    </div>
  </w-slideover>
</template>